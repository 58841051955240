import React from 'react'

import Layout from '../components/layout'

import { Divider, Intro, Feed, Video } from '../components'

const IndexPage = () => (
  <Layout hideFooterLogo={true} pageDescription="Stick it to boring.">
    <Video role="main" />
    <Intro />
    <Divider />
    <Feed />
    <Divider />
  </Layout>
)

export default IndexPage
